import React from "react"
import { Routes } from "../../data/routes"
import { interpolateString } from "../../helpers/Strings"

import {
  SecondaryButton,
  SecondaryOutlineButton,
} from "../../components/button"
import Card from "../../components/card"
import Img from "../../components/img"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const Content = {
  pageName: "Tutor Onboarding",
  header: `Welcome to Tutor Onboarding!`,
  headerImage: "tutor-onboarding-header.svg",
  headerImageAltText: "Tutor Onboarding",
  description: `Before starting your first shift, you will need to go through
    Yup's Tutor Onboarding – passing the quizzes and going through 2 of our
    Playposit trainings in order to demonstrate that you are ready to
    tutor on our platform.`,
  onboardingIntro: "During onboarding, we will:",
  onboardingIntroList: [
    "Collect the information we need to get you ready for your first shift",
    "Provide you with the resources you need to become a successful Yup tutor",
  ],
  onboardingClosing: `After scoring 100% on the quizzes and completing the
    trainings, you will receive an email from Yup with the state of your
    application and next steps.`,
  onboardingAction: "Let's get started!",

  // Onboarding steps
  stepTitle: "Step {{number}}. {{name}}",
  steps: [
    {
      step: "Yup Tutor Policies",
      description: `Read the Tutor Policies Guide, which covers our Tutor Growth
            Pathway, scheduling procedures, and general policies on conducting sessions.

            Once you've reviewed our Tutor Policies, take the Tutor Policies quiz.
        `,
      image: "tutor-onboarding-policies.svg",
      imageAltText: "Policies",
      actions: [
        {
          link: "https://docs.google.com/forms/d/19q8dcSczqegsW1pGKvTwSrhkmIEyZ3KXvW3jDwV_nc4/viewform?edit_requested=true",
          text: "Take Quiz",
        },
        {
          link: "https://docs.google.com/presentation/d/1vi-A0ox5TPYzU6HLqsJcRJkckhDSynzPd9_5DxQyrKM/edit#slide=id.g61192bd476_15_2",
          text: "Read Policies",
        },
      ],
    },
    {
      step: "Yup Tutor Dashboard",
      description: `Read the Yup Tutor Dashboard Manual, which covers how to use
            our teaching tools in sessions.

            When you're ready, take the Yup Tutor Dashboard quiz.
        `,
      image: "tutor-onboarding-dashboard.svg",
      imageAltText: "Dashboard",
      actions: [
        {
          link: "https://docs.google.com/forms/d/1wx-VBaDubQKLh_32o2W2b5Ms8EulsMBIVOQqCP4hBFA/viewform?edit_requested=true",
          text: "Take Quiz",
        },
        {
          link: "https://docs.google.com/presentation/d/13D0n5wqeQ3KM6vvEXhXKu4EmsFfAeDLNMyttn_vg6E0/edit#slide=id.g5a91e9bdfd_0_79",
          text: "Read Manual",
        },
      ],
    },
    {
      step: "Teaching Framework",
      description: `Read the Teaching Framework and the Trainings. These trainings go in depth
        on how Pedagogy Strands 1, 2, 3, and 5 should be used to inform best practices while tutoring.`,
      image: "tutor-onboarding-standards.svg",
      imageAltText: "Standards",
      actions: [
        {
          link: "https://docs.google.com/spreadsheets/d/14AmiA3kGP4AInpSfgoCdqGAR_Ns30hCv4y9zuCu_S98/edit#gid=1696127294",
          text: "Read Framework",
          secondary: true,
        },
        {
          link: "https://sites.google.com/yup.com/tutoronboarding/master-resources#h.p_55TSTA5ifSaL",
          text: "Read Trainings",
        },
      ],
    },
    {
      step: "Payment Information",
      description: `Please fill out this survey so we can pay you

            Because our contract sets you up as an Independent Contractor working on Yup's
            tutoring platform, you will need to fill out a Form W-9. Please download Form W-9,
            fill it out, and send a copy to tax.forms@yup.com. Alternatively, you may scan it,
            take a picture of the filled out form, or mail it to our office.

            If you would like to access the original form, visit the IRS website.`,
      image: "tutor-onboarding-payment.svg",
      imageAltText: "Payment",
      actions: [
        {
          link: "https://docs.google.com/forms/d/18dm6oL-2bL9W2n4CA6s0LgFeCv0yV5WmKq5C4JVMhbc/viewform?edit_requested=true",
          text: "Complete Survey",
        },
        {
          link: "https://drive.google.com/file/d/1CkcH6Ry15k0wc9R5jiLbffpWN6BW7Fyf/view",
          text: "Download Form",
        },
        {
          link: "https://www.irs.gov/pub/irs-pdf/fw9.pdf",
          text: "View Original",
        },
      ],
    },
    {
      step: "Get ready to tutor",
      description: `The Master Resources Page contains these links and other resources we
            offer to help you improve.

            While it is not mandatory to go through these resources, we do highly recommend it
            — they should help ease you into using the Yup platform and understanding our
            teaching standards and policies. Please review and bookmark for later.

            After you are made an official tutor:`,
      list: [
        `You will receive an email from Slack to join yuptutors.slack.com. Follow the
        instructions to create an account, and start chatting with Yup tutors!`,
        `You will receive an email from Yup introducing you to Humanity, our scheduling
        platform, as well as an account activation email from Humanity. Read the Yup
        email for context and then activate your Humanity account to get started tutoring!`,
      ],
      image: "tutor-onboarding-ready.svg",
      imageAltText: "Ready",
      actions: [
        {
          link: "https://sites.google.com/yup.com/tutoronboarding/master-resources",
          text: "View Resources",
        },
        {
          link: "https://yuptutors.slack.com/",
          text: "Open Slack",
        },
      ],
    },
  ],
}

function Header() {
  // Original TailwindUI component: https://tailwindui.com/components/marketing/sections/heroes#component-a3c09c7a3b8f4bd90d280722eaff9634
  return (
    <div className="flex flex-col-reverse md:flex-row justify-around items-center mx-auto max-w-7xl">
      <div className="p-8">
        <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl">
          <span className="block xl:inline">{Content.header}</span>
        </h1>
        <p className="mt-3 text-gray-500 sm:mt-5 sm:max-w-xl sm:mx-auto md:mt-5 lg:mx-0">
          {Content.description}
        </p>
        <p className="mt-3 text-xl bold sm:mt-5 sm:max-w-xl sm:mx-auto md:mt-5 lg:mx-0">
          {Content.onboardingIntro}
        </p>
        <ul className="list-disc px-8">
          {Content.onboardingIntroList.map(item => (
            <li className="text-gray-500" key={item}>
              {item}
            </li>
          ))}
        </ul>
        <p className="mt-3 text-gray-500 sm:mt-5 sm:max-w-xl sm:mx-auto md:mt-5 lg:mx-0">
          {Content.onboardingClosing}
        </p>
        <p className="mt-3 text-xl bold sm:mt-5 sm:max-w-xl sm:mx-auto md:mt-5 lg:mx-0">
          {Content.onboardingAction}
        </p>
      </div>
      <div className="p-4 flex-grow">
        <Img
          className="sm:max-w-xl md:max-w-5xl lg:max-w-7xl w-full"
          src={Content.headerImage}
          alt={Content.headerImageAltText}
        />
      </div>
    </div>
  )
}

function StepsSection() {
  // Original TailwindUI component: https://tailwindui.com/components/ecommerce/components/order-history#component-60810ad73ac10929b250e2198d7d83ee
  return (
    <div>
      <div className="max-w-7xl mx-auto px-4 py-8">
        <div className="mt-6">
          {Content.steps.map((step, i) => (
            <Card key={step.step}>
              <div className="flex flex-col md:flex-row space-x-4 sm:min-w-0 sm:flex-1 sm:space-x-6 lg:space-x-8">
                <Img
                  className="flex-none max-h-48 mb-4 w-full md:w-1/4"
                  src={step.image}
                  alt={step.imageAltText}
                />
                <div className="flex flex-col w-full lg:flex-row">
                  <div className="pt-1.5 min-w-0 flex-1 sm:pt-0">
                    <h3 className="text-3xl font-bold text-gray-900">
                      {interpolateString(Content.stepTitle, {
                        number: i + 1,
                        name: step.step,
                      })}
                    </h3>
                    {step.description.split("\n\n").map(description => (
                      <p
                        key={description}
                        className="mb-4 font-medium text-gray-500"
                      >
                        {description}
                      </p>
                    ))}
                    <ul className="list-disc px-4 text-gray-500">
                      {step.list?.map(item => (
                        <li key={item} className="mb-4">
                          {item}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="flex flex-wrap justify-center mt-6 md:justify-start lg:mt-0 lg:ml-6 md:flex-col md:w-1/2 lg:w-1/3">
                    {step.actions.map((action, i) => (
                      <div key={action.text} className="w-full">
                        {i === 0 && !action.secondary ? (
                          <SecondaryButton
                            text={action.text}
                            href={action.link}
                            className="w-full mx-0"
                          />
                        ) : (
                          <SecondaryOutlineButton
                            text={action.text}
                            href={action.link}
                            className="w-full mx-0"
                          />
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </Card>
          ))}
        </div>
      </div>
    </div>
  )
}

const TutorOnboarding = () => {
  return (
    <Layout>
      <Seo
        title={Content.pageName}
        description={Content.description}
        route={Routes.tutorOnboarding}
      />
      <Header />
      <StepsSection />
    </Layout>
  )
}

export default TutorOnboarding
